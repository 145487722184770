<template>
  <div class="home">
    <v-container fluid>
      <v-row justify="center" align="center">
        <v-col :cols="mode ? 10 : 6">
          <ModeSelector v-if="!mode" v-model="mode" />
          <ProductList
            v-if="mode === 'tds'"
            :products="products"
            @back-to-workflows="mode = ''"
          />
          <ProductCSV
            v-if="mode === 'coa'"
            :products="products"
            @back-to-workflows="mode = ''"
          />
          <ProductDistroList
            v-if="mode === 'distro'"
            :products="products"
            @back-to-workflows="mode = ''"
          />
          <ParseShipHistory
            v-if="mode === 'hubspot'"
            @back-to-workflows="mode = ''"
          />
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="productsLoading" class="text-center">
      <v-progress-circular indeterminate size="64" />
      <div class="loading-note pt-10 align-center">
        Loading products...
      </div>
    </v-overlay>
  </div>
</template>

<script>
import axios from "axios";
import ModeSelector from "@/components/ModeSelector.vue";
import ProductList from "@/components/ProductList.vue";
import ProductCSV from "@/components/ProductCSV.vue";
import ProductDistroList from "@/components/ProductDistroList.vue";
import ParseShipHistory from "@/components/ParseShipHistory.vue";

export default {
  name: "Home",
  data() {
    return {
      mode: "",
      products: [],
      productsLoading: false
    };
  },
  components: {
    ModeSelector,
    ProductList,
    ProductCSV,
    ProductDistroList,
    ParseShipHistory
  },
  methods: {
    async loadProducts() {
      this.productsLoading = true;
      const { data } = await axios.get(
        "https://admin.bxcell.com/wp-json/bxcell/v1/products"
      );
      this.products = data;
      this.productsLoading = false;
    }
  },
  async mounted() {
    await this.loadProducts();
  }
};
</script>
