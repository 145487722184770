<template>
  <div class="product-csv">
    <v-card>
      <v-card-title primary-title class="pa-0">
        <v-toolbar dark class="teal darken-2">
          <v-toolbar-title>Upload Ship History CSV</v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="button-container pt-2 pb-2 pl-2">
            <v-btn text @click="emitBackToWorkflows">
              Back To Workflows
              <v-icon right>
                arrow_back
              </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <div class="csv-upload py-5">
          <v-file-input
            v-model="csv"
            placeholder="Upload Ship History file"
            filled
            prepend-icon="publish"
            accept=".csv"
          />
        </div>
        <div v-if="parsedData.length" class="message ma-0">
          <p class="text-center">
            CSV parse complete. {{ this.count }} records read.
            {{ this.passed }} records passed.
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div class="button-group">
          <v-btn
            class="mr-2"
            :disabled="!csv"
            color="error"
            @click="clearCSVData"
          >
            Clear CSV
          </v-btn>
          <v-btn
            color="success"
            class="mr-2"
            :disabled="!csv"
            @click="parseCSV"
          >
            Parse Shipping Data
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading" class="text-center">
      <v-progress-circular indeterminate size="64" />
      <div class="loading-note pt-10 align-center">
        Generating {{ data.length }} HS Ship History...
      </div>
    </v-overlay>
  </div>
</template>

<script>
import Papa from "papaparse";

export default {
  data() {
    return {
      csv: null,
      data: [],
      headers: [],
      displaySendConfirmation: false,
      loading: false,
      count: 0,
      passed: 0,
      header: "",
      currentOrder: {
        products: [],
        clones: new Set(),
        stage: "Shipped",
        pipeline: "Ecommerce Pipeline"
      },
      parsedData: []
    };
  },
  methods: {
    emitBackToWorkflows() {
      this.clearCSVData();
      this.$emit("back-to-workflows");
    },
    clearCSVData() {
      this.csv = null;
      this.data = [];
      this.headers = [];
      this.parsedData = [];
    },
    checkEmail(rawEmail) {
      const email = rawEmail.replace(/ /g, "");
      const excludedEmailDomains = [
        "@nordicbiosite.com",
        "@lab-research.com",
        "@thermofisher.com",
        "@cellzion.cl",
        "@assaymatrix.com",
        "@vwr.com",
        "@nordicbiosite.com",
        "@cedarlanelabs.com",
        "@quartzy.com",
        "@biozol.de",
        "@labex.net",
        "@tokyofuturestyle.com",
        "@hoelzel.de",
        "@bio-connect.nl",
        "@bxcell.com",
        "@fastbio.com.br",
        "@cienciaybiotecnologia.com",
        "@neobioscience.com",
        "@wolcavi.com",
        "@univ-bio.com",
        "@svenbiolabs.cz",
        "@euromedex.com",
        "@dbaitalia.it",
        "@lab-a-porter.com",
        "@2bscientific.com",
        "@enco.co.il",
        "@tokyofuturestyle.com",
        "@syn-c.com",
        "@iwai-chem.co.jp",
        "@bxcell.co.kr",
        "@bxcell.sg",
        "@abyntek.com",
        "@lubio.ch",
        "@integrated-bio.com",
        "@genbiotek.com",
        "@zageno.com",
        "@iwaichem.com",
        "@bxcell.co.kr",
        "@omnicell.com.sg"
      ];

      const excludedEmails = [
        "accounting",
        "accouting",
        "AP",
        "ap",
        "confirmations",
        "ebill",
        "enco",
        "eprocurement",
        "info",
        "labpurchasing",
        "marketsourcepurchasing",
        "orderconfirmations",
        "order-notifications",
        "procure",
        "procurement",
        "procurment",
        "provprocconfirmation",
        "puchasing",
        "purchase",
        "purchasing",
        "orders.assitant",
        "sales",
        "bxcell",
        "orders",
        "markersourcepurchasing"
      ];
      if (!email) return;
      const emailDomain = email.replace(/[\w.]+(?=@)/g, "").toLowerCase();
      if (excludedEmailDomains.find(domain => domain === emailDomain)) return;
      const emailName = email.replace(/@[\w.]+/g, "").toLowerCase();
      if (excludedEmails.find(emailAddress => emailAddress === emailName))
        return;
      return email;
    },

    isAcademic(email) {
      const qualifiers = [".org", ".edu", ".gov"];
      let academic = false;
      qualifiers.forEach(q => {
        if (email.includes(q)) academic = true;
      });
      return academic;
    },

    processContact(rawContact) {
      const contact = rawContact
        .replace("Attn: ", "")
        .replace(/[0-9_\-/,]/g, "")
        .trim();
      return contact;
    },

    makeProduct(sku, vial, qty, email) {
      const variant = this.isAcademic(email) ? "A" : "R";
      return {
        sku: `${sku}-${variant}${vial.padStart(3, "0")}mg`,
        qty
      };
    },
    async generateCSV() {
      let csvString = "data:text/csv;charset=utf-8,";
      const headers = this.header.map(({ id }) => id);
      csvString = `${csvString}${headers.join(",") + "\n"}`;
      for (const row of this.parsedData) {
        csvString = `${csvString}${Object.values(row).join(",") +
          "\n"}`.replace(/[#]/g, "");
      }
      return csvString;
    },
    async downloadCSV() {
      const csvString = await this.generateCSV();
      const csvURI = encodeURI(csvString);
      const link = document.createElement("a");
      link.setAttribute("href", csvURI);
      link.setAttribute("download", "parsedHSImportData.csv");
      document.body.appendChild(link);

      link.click();
    },
    async parseCSV() {
      const parseCSVPromise = file => {
        return new Promise((complete, error) => {
          Papa.parse(file, {
            complete,
            error,
            header: true,
            skipEmptyLines: true,
            step: ({ data }) => {
              this.count += 1;
              this.header = [
                ...Object.keys(this.currentOrder).map(value => {
                  return { id: value, title: value };
                })
              ];
              if (data.Country !== "US") return;
              const email = this.checkEmail(data.Email);
              if (!email) return;
              if (
                this.currentOrder.PO &&
                this.currentOrder.PO !== data["PO Number"]
              ) {
                this.currentOrder.products = this.currentOrder.products.join(
                  "; "
                );
                this.currentOrder.clones = Array.from(
                  this.currentOrder.clones
                ).join("; ");
                this.parsedData.push(this.currentOrder);
                this.passed += 1;
                this.currentOrder = {
                  products: [],
                  clones: new Set(),
                  stage: "Shipped",
                  pipeline: "Ecommerce Pipeline"
                };
              }
              this.currentOrder.PO = data["PO Number"];
              data.Qty = Math.abs(parseInt(data.Qty, 10));
              this.currentOrder.contact = this.processContact(data.Contact);
              const { qty, sku } = this.makeProduct(
                data["Catalog Number"],
                data["Vial Size"],
                data.Qty,
                email
              );
              for (let index = 0; index < qty; index++) {
                this.currentOrder.products.push(sku);
              }
              this.currentOrder.shipDate = data["Ship Date"];
              this.currentOrder.closeDate = data["Ship Date"];
              this.currentOrder.insitution = data.Institution.replace(
                /[#,]/g,
                ""
              );
              this.currentOrder.email = email.replace(/[#,]/g, "");
              this.currentOrder.phone = data.Phone.replace(/[#,]/g, "");
              this.currentOrder.address = `${data["Address 1"]} ${data["Address 2"]}`
                .trim()
                .replace(/[#,]/g, "");
              this.currentOrder.city = data.City.replace(/[#,]/g, "");
              this.currentOrder.state = data.State.replace(/[#,]/g, "");
              this.currentOrder.zip = data.Zip.replace(/[#,]/g, "");
              this.currentOrder.country = data.Country.replace(/[#,]/g, "");
              this.currentOrder.clones.add(data["Clone"]);
            }
          });
        });
      };
      await parseCSVPromise(this.csv);
      await this.downloadCSV();
    }
  }
};
</script>
