<template>
  <div class="product-list">
    <v-card>
      <v-card-title primary-title class="pa-0">
        <v-toolbar dark class="teal darken-2">
          <v-toolbar-title>Select Product(s)</v-toolbar-title>
          <v-spacer></v-spacer>
          <div v-if="selected.length" class="button-container pa-2">
            <v-btn text @click="clearSelected">
              Clear Selection
              <v-icon right>clear</v-icon>
            </v-btn>
          </div>
          <div class="button-container pa-2">
            <v-btn v-if="!displaySearchField" text @click="toggleSearchField">
              Search
              <v-icon right>mdi-magnify</v-icon>
            </v-btn>
            <v-text-field
              v-else
              label="Search for products..."
              hide-details="true"
              class="align-center"
              @keyup.enter="searchProducts"
              @keyup.esc="toggleSearchField"
              v-model="searchTerm"
            >
              <v-icon slot="append" @click="searchProducts">search</v-icon>
              <v-icon slot="prepend" @click="toggleSearchField">close</v-icon>
            </v-text-field>
          </div>
          <div class="button-container pt-2 pb-2 pl-2">
            <v-btn text @click="toggleSelectAll">
              Select All
              <v-icon right>
                mdi-checkbox-marked-circle
              </v-icon>
            </v-btn>
          </div>
          <div class="button-container pt-2 pb-2 pl-2">
            <v-btn text @click="emitBackToWorkflows">
              Back To Workflows
              <v-icon right>
                arrow_back
              </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="pa-0">
        <v-list two-line style="max-height: 680px" class="overflow-y-auto">
          <v-list-item-group
            v-model="selected"
            multiple
            active-class="teal lighten-5"
          >
            <template v-for="(product, index) in displayedProducts">
              <v-list-item :key="index">
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title v-text="product.post_title" />
                    <v-list-item-subtitle
                      class="text--primary"
                      v-text="product.acf.clone"
                    />
                    <v-list-item-subtitle v-text="product.sku" />
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-icon v-if="active" color="success">
                      mdi-checkbox-marked-circle
                    </v-icon>
                    <v-icon v-else>add_circle_outline</v-icon>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div class="button-group">
          <v-btn
            :disabled="!selected.length"
            color="success"
            @click="downloadPDFs"
          >
            Generate TDS PDFs
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading" class="text-center">
      <v-progress-circular indeterminate size="64" />
      <div class="loading-note pt-10 align-center">
        Generating {{ selected.length }} PDFs...
      </div>
    </v-overlay>
    <ErrorDialog
      v-if="displayError"
      :showDialog="displayError"
      v-model="displayError"
    >
      <template v-slot:error-text>
        <h3 class="error--text">{{ error.name }}</h3>
        <p>{{ error.message }}</p>
      </template>
    </ErrorDialog>
  </div>
</template>

<script>
import axios from "axios";
import Fuse from "fuse.js";
import ErrorDialog from "./ErrorDialog.vue";

export default {
  name: "ProductList",
  data: () => ({
    selected: [],
    selectedProducts: [],
    loading: false,
    displayedProducts: [],
    displaySearchField: false,
    searchTerm: "",
    searchEngine: null,
    displayError: false,
    error: null
  }),
  props: {
    products: Array
  },
  components: {
    ErrorDialog
  },
  methods: {
    async initSearch() {
      this.searchEngine = new Fuse(this.products, {
        keys: ["post_title", "sku", "acf.clone"]
      });
    },
    async searchProducts() {
      const results = await this.searchEngine.search(this.searchTerm);
      this.displayedProducts = results.map(result => {
        return result.item;
      });
    },
    clearSelected() {
      this.selected = [];
    },
    clearSearch() {
      this.searchTerm = "";
      this.displayedProducts = this.products;
    },
    toggleSelectAll() {
      if (this.selected.length != this.products.length) {
        this.clearSelected();
        for (const index in this.products) {
          this.selected.push(parseInt(index, 10));
        }
      } else {
        this.clearSelected();
      }
    },
    toggleSearchField() {
      this.clearSearch();
      this.displaySearchField = !this.displaySearchField;
    },
    emitBackToWorkflows() {
      this.clearSelected();
      this.clearSearch();
      this.$emit("back-to-workflows");
    },
    async downloadPDFs() {
      this.loading = true;
      const generatorPath = `${
        process.env.NODE_ENV === "production"
          ? "https://tdscoapdfgenerator.bxcell.com"
          : "http://localhost:3000"
      }/generate/tds`;
      let response;
      try {
        response = await axios.post(generatorPath, {
          products: this.selectedProducts
        });
        if (typeof response.data === "object" && response.data !== null) {
          this.loading = false;
          this.error = response.data;
          this.displayError = true;
          return;
        }
      } catch (error) {
        this.loading = false;
        this.error = error;
        this.displayError = true;
        return;
      }
      const downloadPath = response.data;
      this.loading = false;
      window.open(
        `${
          process.env.NODE_ENV === "production"
            ? "https://tdscoapdfgenerator.bxcell.com"
            : "http://localhost:3000"
        }${downloadPath}`
      );
    }
  },
  watch: {
    selected() {
      const selectedProducts = [];
      for (const index of this.selected) {
        selectedProducts.push(this.displayedProducts[index]);
      }
      this.selectedProducts = selectedProducts;
    }
  },
  async mounted() {
    await this.initSearch();
    this.displayedProducts = this.products;
  }
};
</script>
