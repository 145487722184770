<template>
  <div class="product-distro-list">
    <v-card>
      <v-card-title primary-title class="pa-0">
        <v-toolbar dark class="teal darken-2">
          <v-toolbar-title>Download Product List</v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="button-container pt-2 pb-2 pl-2">
            <v-btn text @click="emitBackToWorkflows">
              Back To Workflows
              <v-icon right>
                arrow_back
              </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <div class="button-group">
          <v-btn color="success" @click="downloadJSON">
            Download Product JSON
          </v-btn>
        </div>
        <div class="button-group">
          <v-btn color="success" @click="downloadCSV">
            Download Product CSV
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ObjectsToCsv from "objects-to-csv";

export default {
  name: "ProductDistroList",
  props: {
    products: Array
  },
  methods: {
    emitBackToWorkflows() {
      this.$emit("back-to-workflows");
    },
    async makeProducts() {
      const products = [];
      for (const {
        post_title: name,
        sku,
        acf,
        pricing: { variations }
      } of this.products) {
        const sizes = new Set();
        variations.forEach(({ attributes: { attribute_pa_size: size } }) => {
          if (size) sizes.add(size);
        });
        products.push({
          name,
          sku,
          clone: acf.clone,
          sizes: Array.from(sizes).join("; "),
          wbImage: acf.western_blot_image
        });
      }
      return products;
    },
    async downloadCSV() {
      const products = await this.makeProducts();
      const csv = new ObjectsToCsv(products);
      let csvString = await csv.toString();
      csvString = `data:text/csv;charset=utf-8,${csvString}`;
      const encodedUri = encodeURI(csvString);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "products.csv");
      document.body.appendChild(link); // Required for FF

      link.click();
    }
  }
};
</script>
