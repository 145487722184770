<template>
  <v-dialog v-model="displayDialog" width="500">
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Oops! Something Went Wrong
      </v-card-title>

      <v-card-text class="pt-8">
        <slot name="error-text"></slot>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" text @click="displayDialog = false">
          Understood
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ErrorDialog",
  data() {
    return {
      displayDialog: false
    };
  },
  props: {
    showDialog: Boolean
  },
  watch: {
    showDialog: {
      immediate: true,
      handler() {
        this.displayDialog = this.showDialog;
      }
    },
    displayDialog() {
      this.$emit("input", this.displayDialog);
    }
  }
};
</script>
