<template>
  <nav>
    <v-app-bar color="teal" dark app>
      <img
        class="mr-3"
        :src="require('../assets/BioXLogo--White.svg')"
        height="40"
      />
      <v-spacer></v-spacer>
      <v-toolbar-title class="text-uppercase">
        <span class="font-weight-black">Product Data Sheet Generator</span>
      </v-toolbar-title>
    </v-app-bar>
  </nav>
</template>

<script>
export default {};
</script>
