










































import Vue from "vue";
export default Vue.extend({
  name: "ModeSelector",
  methods: {
    emitModeSelection(mode: string) {
      this.$emit("input", mode);
    }
  }
});
