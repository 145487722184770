









import Vue from "vue";
import AppNavbar from "@/components/AppNavbar.vue";

export default Vue.extend({
  name: "App",
  components: {
    AppNavbar
  }
});
