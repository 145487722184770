<template>
  <div class="product-csv">
    <v-card>
      <v-card-title primary-title class="pa-0">
        <v-toolbar dark class="teal darken-2">
          <v-toolbar-title>Upload Lot CSV</v-toolbar-title>
          <v-spacer></v-spacer>
          <div class="button-container pt-2 pb-2 pl-2">
            <v-btn text @click="emitBackToWorkflows">
              Back To Workflows
              <v-icon right>
                arrow_back
              </v-icon>
            </v-btn>
          </div>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <div v-if="!data.length" class="csv-upload py-5">
          <v-file-input
            v-model="csv"
            placeholder="Upload Lot CSV file"
            filled
            prepend-icon="publish"
            accept=".csv"
          />
        </div>
        <v-data-table
          v-if="data.length"
          :headers="headers"
          :items="data"
          pagination.sync="pagination"
          item-key="Lot Number"
          :loading="loading"
        >
          <template v-slot:top>
            <p class="font-weight-bold">Lot Data Preview</p>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <div class="button-group">
          <v-btn
            class="mr-2"
            :disabled="!csv"
            color="error"
            @click="clearCSVData"
          >
            Clear CSV
          </v-btn>
          <v-btn
            color="success"
            class="mr-2"
            :disabled="!csv"
            @click="parseLotCSV"
          >
            Preview COA Data
          </v-btn>
          <v-btn
            :disabled="!data.length"
            color="success"
            @click="generateCoaPdfs"
          >
            Generate COA PDFs
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <v-overlay :value="loading" class="text-center">
      <v-progress-circular indeterminate size="64" />
      <div class="loading-note pt-10 align-center">
        Generating {{ data.length }} COA PDFs...
      </div>
    </v-overlay>
    <ErrorDialog
      v-if="displayError"
      :showDialog="displayError"
      v-model="displayError"
    >
      <template v-slot:error-text>
        <h3 class="error--text">{{ error.name }}</h3>
        <p>{{ error.message }}</p>
        <pre>
          <code>
            {{error.data}}
          </code>
        </pre>
      </template>
    </ErrorDialog>
  </div>
</template>

<script>
import axios from "axios";
import Papa from "papaparse";
import ErrorDialog from "./ErrorDialog.vue";

export default {
  name: "ProductsCSV",
  data() {
    return {
      csv: null,
      data: [],
      headers: [],
      displaySendConfirmation: false,
      loading: false,
      displayError: false,
      error: null
    };
  },
  props: {
    products: Array
  },
  components: {
    ErrorDialog
  },
  methods: {
    emitBackToWorkflows() {
      this.clearCSVData();
      this.$emit("back-to-workflows");
    },
    async generateCoaPdfs() {
      this.loading = true;
      const data = {
        lots: this.data,
        products: this.products
      };
      const generatorPath = `${
        process.env.NODE_ENV === "production"
          ? "https://tdscoapdfgenerator.bxcell.com"
          : "http://localhost:3000"
      }/generate/coa`;
      let response;
      try {
        response = await axios.post(generatorPath, data);
        if (typeof response.data === "object" && response.data !== null) {
          this.loading = false;
          this.error = response.data;
          this.displayError = true;
          return;
        }
      } catch (error) {
        this.loading = false;
        this.error = error;
        this.displayError = true;
        return;
      }
      const downloadPath = response.data;
      this.loading = false;
      window.open(
        `${
          process.env.NODE_ENV === "production"
            ? "https://tdscoapdfgenerator.bxcell.com"
            : "http://localhost:3000"
        }${downloadPath}`
      );
    },
    clearCSVData() {
      this.csv = null;
      this.data = [];
      this.headers = [];
    },
    async parseCSV(file) {
      const parseCSVPromise = function(file) {
        return new Promise(function(complete, error) {
          Papa.parse(file, {
            complete,
            error,
            header: true,
            skipEmptyLines: true
          });
        });
      };
      let results = [];
      await parseCSVPromise(file).then(function(parsedData) {
        const { data } = parsedData;
        results = data;
      });
      return results;
    },
    async parseLotCSV() {
      if (!this.csv) return;
      this.loading = true;
      this.data = await this.parseCSV(this.csv);
      for (const [index, header] of Object.keys(this.data[0]).entries()) {
        this.headers.push({
          text: header,
          align: index ? "center" : "start",
          sortable: true,
          value: header
        });
      }
      this.loading = false;
    }
  }
};
</script>
